/* mixin for multiline */ /*
Media query mixin
 */
.responsiveList {
  padding: 9px;
  column-gap: 0px;
  box-sizing: border-box;
}

@media (min-width: 765px) {
  .responsiveList {
    width: 500px;
    columns: 2;
  }
}
@media (max-width: 764px) {
  .responsiveList {
    columns: 1;
  }
}
