/* mixin for multiline */ /*
Media query mixin
 */
.horizontalScroll {
  position: relative;
  width: 100%;
  margin: 0px;
  box-sizing: border-box;
}
.horizontalScroll__scroll-wrapper {
  overflow: hidden;
  width: 100%;
}
.horizontalScroll__content {
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  box-sizing: border-box;
}
.horizontalScroll__content.-disable-scroll-bar {
  padding-bottom: 50px;
  margin-bottom: -50px;
}
.horizontalScroll__content.-snapping {
  scroll-snap-type: x mandatory;
}
.horizontalScroll__content.-snapping > * {
  scroll-snap-align: center;
}
.horizontalScroll__content > * {
  display: inline-block;
}
.horizontalScroll__content > *:last-child {
  margin-right: 0px;
}
.horizontalScroll__content.-lock {
  overflow-x: hidden;
}
.horizontalScroll__content-left-fade {
  top: 0px;
  height: 100%;
  width: 40px;
  z-index: 3;
  left: 0px;
  position: absolute;
  pointer-events: none;
}
.horizontalScroll__content-left-fade.-grey {
  background: linear-gradient(to right, rgba(242, 242, 244, 0.9) 0%, rgba(251, 251, 251, 0) 100%);
}
.horizontalScroll__content-left-fade.-white {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.9) 0%, rgba(251, 251, 251, 0) 100%);
}
.horizontalScroll__content-right-fade {
  top: 0px;
  height: 100%;
  width: 40px;
  z-index: 3;
  right: 0px;
  position: absolute;
  pointer-events: none;
}
.horizontalScroll__content-right-fade.-grey {
  background: linear-gradient(to left, rgba(242, 242, 244, 0.9) 0%, rgba(251, 251, 251, 0) 100%);
}
.horizontalScroll__content-right-fade.-white {
  background: linear-gradient(to left, rgba(255, 255, 255, 0.9) 0%, rgba(251, 251, 251, 0) 100%);
}

@media (min-width: 765px) {
  .horizontalScroll__content > * {
    width: 250px;
    margin-right: 18px;
  }
}
@media (max-width: 764px) {
  .horizontalScroll__content {
    padding-left: calc(5vw - 2px);
    padding-right: calc(5vw - 2px);
    padding-bottom: 50px;
    margin-bottom: -50px;
    width: 100%;
    overflow-x: scroll;
  }
  .horizontalScroll__content > * {
    width: 250px;
    margin-right: 18px;
  }
}
