/* mixin for multiline */ /*
Media query mixin
 */
.searchInput {
  position: relative;
}
.searchInput__close {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 40px;
  height: 100%;
  background-image: url("../../../Assets/images/ui/close-dark.svg");
  background-size: 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.searchInput__input {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  outline: none;
  background-color: #f2f2f4;
  border: none;
  position: relative;
  background-image: url("../../../Assets/images/ui/search-dark.svg");
  background-size: 15px;
  background-position: 18px 50%;
  background-repeat: no-repeat;
}
.searchInput__input::placeholder {
  color: rgba(1, 1, 1, 0.2);
}
.searchInput__glass {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50px;
  height: 100%;
  box-sizing: border-box;
  padding: 15px 10px;
  cursor: pointer;
}
.searchInput__glass .searchSVG__border {
  fill: #434343 !important;
}

@media (min-width: 765px) {
  .searchInput__input {
    padding: 18px 36px;
    padding-left: 51px;
  }
}
@media (max-width: 764px) {
  .searchInput__input {
    padding: 18px 18px;
    padding-left: 51px;
  }
}
