/* mixin for multiline */ /*
Media query mixin
 */
.title {
  width: 100%;
  overflow-wrap: break-word;
  text-transform: uppercase;
  position: relative;
  display: inline;
  z-index: 200;
}
.title__text {
  margin: 0px;
  vertical-align: top;
}
.title.-grey {
  color: #434343;
}
.title.-pink {
  color: #ee2595;
}
.title.-orange {
  color: #e77610;
}
.title.-yellow {
  color: #f4a71c;
}
.title.-blue {
  color: #3399ff;
}
.title.-purple {
  color: #9225c9;
}
.title.-white {
  color: #f2f2f4;
}
.title__dropdown-box {
  position: absolute;
  top: 100%;
  font-family: "brandon-grotesque", sans-serif;
}
.title__dropdown-box .react-datepicker {
  border: 0;
}
.title__dropdown-box .react-datepicker__navigation {
  top: calc(1em + 6px);
}
.title__dropdown-box .react-datepicker__navigation--previous {
  left: calc(1.5em + 2px);
}
.title__dropdown-box .react-datepicker__navigation--next {
  right: calc(1.5em + 2px);
}
.title__dropdown-box .react-datepicker__navigation-icon:before {
  border-color: #3399ff;
  border-width: 2px 2px 0 0;
}
.title__dropdown-box .react-datepicker__month-container {
  padding: 1em 1.5em;
  background-color: #f2f2f4;
}
.title__dropdown-box .react-datepicker__header {
  background-color: #f2f2f4;
  border-bottom: 0;
}
.title__dropdown-box .react-datepicker__day-names {
  padding-top: 10px;
  margin-bottom: -15px;
}
.title__dropdown-box .react-datepicker__day-name {
  color: #434343;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
}
.title__dropdown-box .react-datepicker__day {
  margin: 0.332rem 0;
  padding: 0.166rem;
}
.title__dropdown-box .react-datepicker__day.react-datepicker__day--selected {
  background-color: #3399ff;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.title__dropdown-box .react-datepicker__day.react-datepicker__day--selected:before {
  content: " ";
  position: absolute;
  left: 50%;
  width: 50%;
  top: 0;
  height: 100%;
  background-color: #3399ff;
  opacity: 0.5;
  z-index: -1;
}
.title__dropdown-box .react-datepicker__day.react-datepicker__day--today:before {
  content: unset;
}
.title__dropdown-box .react-datepicker__day--in-range, .title__dropdown-box .react-datepicker__day--in-selecting-range {
  background-color: rgba(51, 153, 255, 0.5) !important;
  border-radius: 0 !important;
}
.title__dropdown-box .react-datepicker__day--in-range.react-datepicker__day--range-start, .title__dropdown-box .react-datepicker__day--in-range.react-datepicker__day--selecting-range-start, .title__dropdown-box .react-datepicker__day--in-selecting-range.react-datepicker__day--range-start, .title__dropdown-box .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
  background-color: #3399ff !important;
  border-radius: 50% !important;
  position: relative;
  z-index: 1;
  color: #FFF;
}
.title__dropdown-box .react-datepicker__day--in-range.react-datepicker__day--range-start:before, .title__dropdown-box .react-datepicker__day--in-range.react-datepicker__day--selecting-range-start:before, .title__dropdown-box .react-datepicker__day--in-selecting-range.react-datepicker__day--range-start:before, .title__dropdown-box .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start:before {
  content: " ";
  position: absolute;
  left: 50%;
  width: 50%;
  top: 0;
  height: 100%;
  background-color: #3399ff !important;
  opacity: 0.5;
  z-index: -1;
}
.title__dropdown-box .react-datepicker__day--in-range.react-datepicker__day--range-end, .title__dropdown-box .react-datepicker__day--in-range.react-datepicker__day--selecting-range-end, .title__dropdown-box .react-datepicker__day--in-selecting-range.react-datepicker__day--range-end, .title__dropdown-box .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
  background-color: #3399ff !important;
  border-radius: 50% !important;
  position: relative;
  z-index: 1;
  color: #FFF;
}
.title__dropdown-box .react-datepicker__day--in-range.react-datepicker__day--range-end:before, .title__dropdown-box .react-datepicker__day--in-range.react-datepicker__day--selecting-range-end:before, .title__dropdown-box .react-datepicker__day--in-selecting-range.react-datepicker__day--range-end:before, .title__dropdown-box .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end:before {
  content: " ";
  position: absolute;
  right: 50%;
  width: 50%;
  top: 0;
  height: 100%;
  background-color: #3399ff !important;
  opacity: 0.5;
  z-index: -1;
}
.title__dropdown-box .react-datepicker__day--in-range:last-child, .title__dropdown-box .react-datepicker__day--in-selecting-range:last-child {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.title__dropdown-box .react-datepicker__day--in-range:first-child, .title__dropdown-box .react-datepicker__day--in-selecting-range:first-child {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.title__dropdown-box .react-datepicker__current-month {
  color: #3399ff;
}
.title__dropdown-container {
  display: inline-block;
  vertical-align: top;
  width: 25px;
  height: 100%;
  z-index: 5;
  line-height: inherit;
}
.title__dropdown-button {
  position: relative;
  margin-top: 1em;
  margin-bottom: 0.5em;
  vertical-align: middle;
  display: inline-block;
  left: 0px;
}
.title__measure {
  display: none;
  visibility: hidden;
}
.title.-h3 .title__dropdown-button {
  top: 0.2em;
}
.title__description {
  max-width: 400px;
}

@media (min-width: 765px) {
  .title {
    white-space: nowrap;
  }
  .title__dropdown-container {
    margin-left: 9px;
    position: relative;
  }
  .title__dropdown-box {
    left: 0px;
    transform: translate(-50%, -140%);
  }
  .category__title .title__dropdown-box {
    transform: translate(-50%, 0%);
  }
  .eventsCalendar__title .title__dropdown-box {
    transform: translate(-50%, 0%);
    top: 20px;
  }
}
@media (max-width: 764px) {
  .title__text {
    float: center;
  }
  .title.-expand-with-filter {
    height: auto;
  }
  .title.-expand-with-filter .title__dropdown-container .title__dropdown-box {
    top: 25px;
    transform: translate(0%, -115%);
  }
  .title.-expand-with-filter .title__dropdown-container .title__measure {
    position: relative;
    width: 100vw;
    height: auto;
    float: right;
  }
  .title__dropdown-container {
    margin-left: 9px;
    display: inline-block;
    height: 25px;
    line-height: inherit;
    position: static;
  }
  .title__dropdown-box {
    position: absolute;
    left: 0px;
  }
}
