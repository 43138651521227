/* mixin for multiline */ /*
Media query mixin
 */
.favourites {
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0px;
  z-index: 1000000000;
  max-height: 0px;
  background-image: linear-gradient(-90deg, #ffde4f, #ee2595);
  transition: max-height 0.1s linear;
  box-sizing: border-box;
  padding: 0px;
}
.favourites__title {
  color: white;
  text-transform: uppercase;
  font-family: "brandon-grotesque", sans-serif;
  position: relative;
}
.favourites__close {
  position: absolute;
  width: 25px;
  height: 25px;
}
.favourites__card .card__title {
  color: white;
}
.favourites__inline-heart {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
}
.favourites__form .label__text {
  display: none !important;
}
.favourites__copy-btn {
  display: block;
  margin: 9px auto 0px auto;
  transition: padding 0.2s ease;
}
.favourites__copy-btn:before {
  content: "";
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url("../../Assets/images/ui/checkmark-white.svg");
  opacity: 0;
  transition: opacity 0.2s ease;
}
.favourites__copy-btn.-tick {
  padding-right: 50px;
}
.favourites__copy-btn.-tick:before {
  opacity: 1;
}
.favourites__input {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.07) !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
  color: #434343;
}
.favourites__filter-btn {
  height: 35px;
  line-height: 35px;
  padding: 0px 18px;
  box-shadow: none;
  margin-right: 18px;
}
.favourites__filter-btn.-active {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.favourites__filter-btn.-disabled {
  color: #979797;
  cursor: default;
}
.favourites__filter-btn.-disabled:hover {
  color: #979797;
  background-color: #f2f2f4;
}
.favourites.-has-content .favourites__content {
  margin: 9px 0px 36px 0px;
}

@media (pointer: fine) {
  .favourites__filter-btn:hover {
    background-color: #3399ff;
    color: white;
  }
}
@media (min-width: 765px) {
  .favourites {
    padding: 0px 54px;
  }
  .favourites.-open {
    height: auto;
    max-height: 510px;
  }
  .favourites__title {
    height: 80px;
    line-height: 80px !important;
  }
  .favourites__close {
    top: 27.5px;
    right: 0px;
  }
  .favourites__share-desktop {
    text-transform: none;
    position: absolute;
    height: 25px;
    line-height: 25px;
    top: 27.5px;
    right: 43px;
    color: white;
    cursor: pointer;
    font-family: "brandon-grotesque", sans-serif;
  }
  .favourites__share-mobile {
    display: none;
  }
  .favourites__content {
    display: block;
    width: 100%;
  }
  .favourites__before-content {
    color: white !important;
    height: 25px;
    line-height: 25px;
    vertical-align: top;
    padding: 36px 0px;
    padding-top: 0px;
  }
  .favourites__after-content {
    color: white !important;
    height: 25px;
    line-height: 25px;
    vertical-align: top;
    margin: 36px 0px;
  }
  .favourites .horizontalScroll__content {
    overflow-y: hidden;
  }
  .favourites .horizontalScroll__content > * {
    max-width: 250px;
    margin-right: 18px;
  }
  .favourites__description {
    display: none;
  }
}
@media (max-width: 764px) {
  .favourites {
    transition: none;
    padding: 0px;
  }
  .favourites.-open {
    height: 100vh;
    max-height: 100vh;
  }
  .favourites__title {
    padding: 0px 18px;
    height: 50px;
    line-height: 50px !important;
    font-size: 18px !important;
    border-bottom: white solid 1px;
  }
  .favourites__before-content {
    padding: 18px 18px;
    padding-bottom: 28px;
    width: 100vw;
    white-space: nowrap;
    overflow-x: scroll;
    box-sizing: border-box;
    height: auto;
    margin: 0px;
    margin-bottom: -10px;
    -webkit-overflow-scrolling: touch;
  }
  .favourites__before-content > *:last-child {
    margin-right: 0px;
  }
  .favourites__share-mobile {
    position: absolute;
    width: 100%;
    border-top: 1px solid white;
    height: 60px;
    line-height: 60px !important;
    color: white !important;
    text-align: center;
    cursor: pointer;
    font-family: Arial;
    bottom: 0px;
    left: 0px;
  }
  .favourites__share-desktop {
    display: none;
  }
  .favourites__before-content-wrapper {
    height: auto;
    overflow: hidden;
  }
  .favourites__close {
    top: 12.5px;
    right: 18px;
  }
  .favourites__content {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin: 0px !important;
    padding: 0px 18px;
    padding-bottom: 108px;
    height: calc(
        100vh - 183px
      );
  }
  .favourites__content.-no-filter-bar {
    overflow: visible !important;
    height: calc(100vh - 35px);
  }
  .favourites__content.-no-filter-bar .horizontalScroll__content {
    overflow: visible !important;
  }
  .favourites__content.-no-filter-bar .favourites__share-mobile {
    display: none;
  }
  .favourites__card {
    display: block;
  }
  .favourites .card {
    position: relative;
    width: 100%;
    margin: 0px;
    margin-bottom: 18px;
    margin-right: 0px !important;
  }
  .favourites .card__above-content {
    display: none;
  }
  .favourites .card__img-container {
    width: 150px;
    padding: 0px !important;
    margin: 0px !important;
    height: 150px;
  }
  .favourites .card__favourites {
    left: 107px;
  }
  .favourites .card__caption {
    width: calc(100% - 150px);
    padding-left: 18px !important;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
  }
  .favourites .card__title {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .favourites .horizontalScroll__content {
    position: relative;
    width: 100% !important;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    padding: 0px;
    -webkit-overflow-scrolling: touch !important;
  }
  .favourites .horizontalScroll {
    -webkit-mask-image: none !important;
  }
  .favourites .horizontalScroll__scroll-wrapper {
    overflow: visible;
  }
  .favourites .-empty-img {
    display: block;
    color: white;
    margin-bottom: 0px;
    margin-top: 54px;
  }
  .favourites__description {
    color: white;
    display: block;
    width: 100%;
    white-space: pre-wrap;
    padding: 9px;
    box-sizing: border-box;
    margin-bottom: 54px;
  }
  .favourites .emptyCard:not(.-empty-img) {
    display: block;
  }
  .favourites__after-content {
    display: none;
  }
}
