/* mixin for multiline */ /*
Media query mixin
 */
.selectFake__input {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  outline: none;
  background-color: #f2f2f4;
  border: none;
}
.selectFake__input::placeholder {
  color: rgba(1, 1, 1, 0.2);
}
.selectFake .label__content {
  position: relative;
}
.selectFake__overlay {
  position: absolute;
  top: 54px;
  left: 0;
  width: 100%;
  z-index: 999999;
  box-sizing: border-box;
  background-color: #f2f2f4;
  padding: 5px 15px;
  visibility: visible;
  opacity: 1;
  transition: all 0.2s linear;
}
.selectFake__overlay > div {
  text-transform: uppercase;
  color: #979797;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 5px 5px;
  cursor: pointer;
}
.selectFake__overlay > div:hover {
  color: #3399ff;
}
.selectFake__overlay.hidden {
  visibility: hidden;
  opacity: 0;
}

.selectFake.longlist .selectFake__overlay {
  max-height: 36vh;
  overflow-y: scroll;
}
.selectFake.longlist .selectFake__overlay::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ededf0;
}
.selectFake.longlist .selectFake__overlay::-webkit-scrollbar {
  width: 4px;
  background-color: #ededf0;
}
.selectFake.longlist .selectFake__overlay::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #fcdf4f;
}
.selectFake.longlist .selectFake__overlay > div {
  font-size: 14px;
  padding: 2px 5px;
}

@media (min-width: 765px) {
  .selectFake__input {
    padding: 18px 36px;
  }
}
@media (max-width: 764px) {
  .selectFake__input {
    padding: 18px 18px;
  }
  .selectFake__input.-transparent {
    color: white;
    background-color: rgba(255, 255, 255, 0.35) !important;
  }
  .selectFake__input.-transparent::placeholder {
    opacity: 1;
    color: #f2f2f4 !important;
  }
}
