/* mixin for multiline */ /*
Media query mixin
 */
.slideUpDialogue {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 200000;
  transition: opacity 0.2s ease;
}
.slideUpDialogue.-closed {
  opacity: 0;
  pointer-events: none;
}
.slideUpDialogue__box {
  position: fixed;
  z-index: 200002;
  background: #f2f2f4;
  box-shadow: 0 2px 4px 0 rgba(187, 136, 136, 0.3);
  padding: 18px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr;
  grid-template-areas: "Button" "Title" "Divider" "Content";
}
.slideUpDialogue__box.-closed {
  pointer-events: none;
}
.slideUpDialogue__close-btn {
  display: block;
  margin: 0px auto;
  grid-area: Button;
}
.slideUpDialogue__title {
  margin: 18px 0px;
  text-transform: uppercase;
  text-align: center;
  grid-area: Title;
  font-family: "brandon-grotesque", sans-serif;
}
.slideUpDialogue__divider {
  background-color: rgba(0, 0, 0, 0.07);
  grid-area: Divider;
}
.slideUpDialogue__content {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  grid-area: Content;
}

@media (min-width: 765px) {
  .slideUpDialogue {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .slideUpDialogue__box {
    min-width: 400px;
    display: inline-block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s ease;
  }
  .slideUpDialogue__box.-closed {
    opacity: 0;
    pointer-events: none;
  }
  .slideUpDialogue__content {
    max-height: 400px;
  }
}
@media (max-width: 764px) {
  .slideUpDialogue {
    background-color: rgba(180, 180, 180, 0.5);
  }
  .slideUpDialogue__box {
    width: 100%;
    left: 0px;
    bottom: 0px;
    transition: max-height 0.2s ease;
    overflow: hidden;
  }
  .slideUpDialogue__box.-open {
    max-height: 80vh;
  }
  .slideUpDialogue__box.-closed {
    max-height: 0px;
    height: 0px;
    padding: 0px;
  }
}
