/* mixin for multiline */ /*
Media query mixin
 */
.textArea {
  width: 100%;
  box-sizing: border-box;
  resize: none;
  display: block;
}
.textArea.-transparent {
  color: white;
  background-color: rgba(255, 255, 255, 0.35) !important;
}
.textArea.-transparent::placeholder {
  opacity: 1;
  color: #f2f2f4;
}
