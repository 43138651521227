/* mixin for multiline */ /*
Media query mixin
 */
.loaderSvg__point {
  fill: #f4a71c;
  animation-name: spin;
  animation-duration: 0.7s;
  opacity: 0.1;
  animation-iteration-count: infinite;
}
.loaderSvg__0 {
  animation-delay: 0s;
}
.loaderSvg__1 {
  animation-delay: 0.07s;
}
.loaderSvg__2 {
  animation-delay: 0.14s;
}
.loaderSvg__3 {
  animation-delay: 0.21s;
}
.loaderSvg__4 {
  animation-delay: 0.28s;
}
.loaderSvg__5 {
  animation-delay: 0.35s;
}
.loaderSvg__6 {
  animation-delay: 0.42s;
}
.loaderSvg__7 {
  animation-delay: 0.49s;
}
.loaderSvg__8 {
  animation-delay: 0.56s;
}
.loaderSvg__9 {
  animation-delay: 0.63s;
}

@keyframes spin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
