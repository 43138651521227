/* mixin for multiline */ /*
Media query mixin
 */
.checkbox {
  position: relative;
  padding-left: 29px;
  margin: 0px;
}
.checkbox__box {
  height: 15px;
  width: 15px;
  margin: 5px 9px 5px 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  cursor: pointer;
  appearance: none;
  border: solid #434343 1px;
  background-size: 80%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.checkbox__box:checked {
  background-color: #434343;
  background-image: url("../../../Assets/images/ui/checkmark-white.svg");
}
.checkbox__box.-white {
  border: solid white 1px;
}
.checkbox__box.-white:checked {
  background-color: white;
  background-image: url("../../../Assets/images/ui/checkmark-grey.svg");
}
.checkbox__label {
  cursor: pointer;
  position: relative;
}
.checkbox__label .richText p:first-child {
  margin-top: 0;
}
.checkbox__label .richText p:last-child {
  margin-bottom: 0;
}
.checkbox__error {
  display: block;
  margin-left: -29px;
  font-style: italic;
  margin-top: 5px;
  color: #f84663 !important;
}
