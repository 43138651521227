/* mixin for multiline */ /*
Media query mixin
 */
.richText p {
  display: block;
  margin: 18px 0px;
  word-wrap: break-word;
}
.richText__image {
  width: 100%;
  height: auto;
  clear: both;
}
.richText__link {
  cursor: pointer;
  color: #f4a71c !important;
  text-decoration: underline !important;
}
.richText__link:hover {
  color: #3399ff !important;
}
.richText ul {
  list-style: circle;
}
.richText li {
  margin-left: 18px;
}
.richText ol {
  list-style-type: decimal;
}
.richText.-default .richText__link {
  color: #f4a71c !important;
}
.richText.-default .richText__link:hover {
  color: #3399ff !important;
}
.richText.-white .richText__link {
  color: white !important;
}
.richText.-white .richText__link:hover {
  color: white !important;
}
.richText.-question .richText__link {
  color: #f4a71c !important;
}
.richText.-question .richText__link:hover {
  color: #3399ff !important;
}
.richText.-question h3 {
  font-family: Arial;
  font-weight: 700;
  font-size: 20px;
}
.richText .youTubeVideo {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  margin: 18px 0;
}
.richText .youTubeVideo iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}
.richText .instagramPost, .richText .instagramReel {
  margin: 18px 0;
}
.richText .instagramPost iframe, .richText .instagramReel iframe {
  max-width: 100% !important;
}
.richText h1,
.richText h2,
.richText h3 {
  font-family: "brandon-grotesque", sans-serif;
}
.richText.-basic p {
  color: rgb(90, 90, 90);
}
.richText.-basic h2 {
  margin-top: 54px;
}
.richText.-basic h3 {
  text-transform: uppercase;
  color: rgb(90, 90, 90);
  margin-top: 54px;
}
.richText.-basic .richText__link {
  color: #f4a71c !important;
}
.richText.-basic .richText__link:hover {
  color: #3399ff !important;
}
