/* mixin for multiline */ /*
Media query mixin
 */
.toggleListItem {
  width: 100%;
  position: relative;
  text-align: left;
}
.toggleListItem__label {
  width: 100%;
  text-transform: uppercase;
  height: 50px;
  position: relative;
  display: block;
  padding: 0px 80px 0px 9px;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "brandon-grotesque", sans-serif;
}
.toggleListItem__label.-checked {
  color: #3399ff;
  font-weight: 700;
}
.toggleListItem__label:hover {
  color: #3399ff;
}
.toggleListItem__toggle {
  position: absolute;
  right: 9px;
  top: 50%;
  transform: translate(0%, -50%);
}
.toggleListItem__text {
  z-index: 10;
}
