/* mixin for multiline */ /*
Media query mixin
 */
.favouritesSVG .favouritesSVG__inside {
  transition: fill 0.2s ease;
}
.favouritesSVG .favouritesSVG__border {
  transition: fill 0.2s ease;
}
.favouritesSVG.-blue .favouritesSVG__inside {
  fill: rgba(0, 0, 0, 0);
}
.favouritesSVG.-blue .favouritesSVG__border {
  fill: #3399ff;
}
.favouritesSVG.-blue:hover .favouritesSVG__inside {
  fill: #ee2595;
}
.favouritesSVG.-blue:hover .favouritesSVG__border {
  fill: rgba(0, 0, 0, 0);
}
.favouritesSVG.-blue-active .favouritesSVG__inside {
  fill: #ee2595;
}
.favouritesSVG.-blue-active .favouritesSVG__border {
  fill: rgba(0, 0, 0, 0);
}
.favouritesSVG.-grey .favouritesSVG__inside {
  fill: rgba(0, 0, 0, 0.2);
}
.favouritesSVG.-grey .favouritesSVG__border {
  fill: white;
}
.favouritesSVG.-grey:hover .favouritesSVG__border {
  fill: #f3b038;
}
.favouritesSVG.-active .favouritesSVG__inside {
  fill: #f3b038;
}
.favouritesSVG.-active .favouritesSVG__border {
  fill: white;
}
.favouritesSVG.-disabled .favouritesSVG__inside {
  fill: rgba(0, 0, 0, 0.2);
}
.favouritesSVG.-disabled .favouritesSVG__border {
  fill: white;
}
