/* mixin for multiline */ /*
Media query mixin
 */
.label__text {
  display: block;
  height: 30px;
  line-height: 30px;
  background-position: 100% 50%;
  background-size: 10px;
  background-repeat: no-repeat;
}
.label__text.-error {
  background-image: url("../../../Assets/images/ui/form-error-x.svg");
}
.label__text.-valid {
  background-image: url("../../../Assets/images/ui/checkmark-grey.svg");
}
.label__error {
  display: block;
  font-size: 14px;
  font-style: italic;
  margin-top: 5px;
  color: #f84663 !important;
}
.label__content {
  width: 100%;
  display: block;
  box-sizing: border-box;
}
