/* mixin for multiline */ /*
Media query mixin
 */
.singleColumnList {
  margin: 9px;
  column-gap: 0px;
}
.singleColumnList.-no-margin {
  margin: 0px;
}

@media (min-width: 765px) {
  .singleColumnList {
    columns: 1;
  }
}
@media (max-width: 764px) {
  .singleColumnList {
    columns: 1;
  }
}
