.link--social-media {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.link--social-media.-facebook {
  background-image: url("../../../Assets/images/ui/icons/facebook.svg");
}
.link--social-media.-instagram {
  background-image: url("../../../Assets/images/ui/icons/instagram.svg");
}
.link--social-media.-twitter {
  background-image: url("../../../Assets/images/ui/icons/twitter.svg");
}
