/* mixin for multiline */ /*
Media query mixin
 */
.cardDate {
  color: white;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0px;
}
.cardDate__month {
  text-align: center;
  text-transform: uppercase;
  border-right: 1px solid white;
  width: 60px;
  height: 50px;
  box-sizing: border-box;
  padding: -5px 0px 0px 0px;
}
.cardDate__month * {
  font-weight: 900;
  font-family: "brandon-grotesque", sans-serif;
}
.cardDate__hour {
  height: 30px;
  position: absolute;
  left: 60px;
  bottom: 0px;
  line-height: 30px;
  padding-left: 15px;
  padding-bottom: 4px;
}
.cardDate__hour * {
  font-weight: 700;
  display: inline-block;
  padding-right: 7px;
  font-family: "brandon-grotesque", sans-serif;
}
