/* mixin for multiline */ /*
Media query mixin
 */
.whatsappchat {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 19999;
}
.whatsappchat iframe {
  border: 0;
  float: right;
}
.whatsappchat img {
  border: 0;
  float: right;
  cursor: pointer;
}
