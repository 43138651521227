/* autoprefixer grid: autoplace */ /* mixin for multiline */ /*
Media query mixin
 */
#emailSubscribe {
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
  width: 500px;
  max-width: 100%;
  max-height: 0;
  transition: max-height 0.5s;
  background-color: #FFFFFF;
  border-radius: 20px;
  box-shadow: 10px 10px 29px -15px rgba(0, 0, 0, 0.75);
  color: #434343;
  text-align: left;
}
#emailSubscribe .esWrapper {
  padding: 20px;
}
#emailSubscribe.active {
  max-height: 800px;
}
#emailSubscribe .close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 22px;
  cursor: pointer;
  font-weight: 800;
  padding: 0px 7px;
}
#emailSubscribe .loading.active {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#emailSubscribe .contacta-webform-form .valign-wrapper {
  position: unset;
  margin: unset;
  top: unset;
}
#emailSubscribe .contacta-webform-form input[type=text] {
  padding: 18px 36px;
  box-sizing: border-box;
  border: 0;
  background-color: #f2f2f4;
}
#emailSubscribe .contacta-webform-form .contacta-webform-table i {
  font-size: inherit;
}
#emailSubscribe .contacta-webform-form .contacta-webform-table a {
  font-style: normal;
  font-size: 16px;
  color: #f4a71c;
  text-decoration: underline;
}
#emailSubscribe .contacta-webform-form .checkbox {
  float: left;
  width: 100%;
  padding-left: 0;
  margin: 5px 0;
}
#emailSubscribe .contacta-webform-form .checkbox .checkbox-option {
  vertical-align: unset;
  margin-right: 5px;
  cursor: pointer;
  appearance: none;
  border: 1px solid #434343;
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  appearance: none;
  cursor: pointer;
  background-color: #f2f2f4;
}
#emailSubscribe .contacta-webform-form .checkbox .checkbox-option:checked {
  background-color: #FFFFFF;
  background-image: url(../../Assets/images/ui/checkmark-grey.svg);
  background-size: 80%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
#emailSubscribe .contacta-webform-form button.btn-primary {
  background-color: #39f !important;
  line-height: 48px;
  padding: 0 36px;
  color: #FFF;
}

.footer {
  position: relative;
  /* overflow: hidden; */
  width: 100%;
  color: white;
  /**
    * Email container
    **/
  /**
    * Divider
    **/
  /**
  * Footer columns
  **/
  /**
  * Language dropdown
  **/
}
.footer .container {
  position: relative;
  z-index: 2;
}
.footer.-option-1 {
  background-image: linear-gradient(to right, #0a51fa, #54c2db);
}
.footer.-option-2 {
  background-image: linear-gradient(to right, #0a51fa, #54c2db);
}
.footer.-option-3 {
  background-color: #00b0bb;
}
.footer__email-container {
  width: 100%;
  display: grid;
}
.footer__email-form-note {
  color: white;
  font-size: 14px;
  font-style: italic;
}
.footer__email-text {
  grid-area: email-text;
  text-transform: uppercase;
  color: #ffe3a8;
  line-height: 30px;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
}
.footer__email-form-container {
  grid-area: email-form;
  position: relative;
  text-align: center;
}
.footer__email-form-container .label__text {
  display: none;
}
.footer__email-form-btn {
  width: auto !important;
  white-space: nowrap;
}
.footer .button--email-form {
  height: 40px;
  line-height: 40px;
  margin-top: 8px;
  border-radius: 6px;
  background-color: #ffe3a8;
  color: #00b0bb;
  font-weight: 700;
}
.footer__email-gdpr {
  display: block;
}
.footer__email-form-btn {
  width: auto !important;
  white-space: nowrap;
}
.footer__email-gdpr {
  display: block;
}
.footer__email-visited {
  display: block;
}
.footer__email-form-input {
  height: 48px;
  border: 0;
  padding: 0;
  padding-left: 20px;
  color: #434343;
  background-color: rgba(255, 255, 255, 0.35);
}
.footer__email-form-input::placeholder {
  opacity: 1;
  color: #f2f2f4;
}
.footer__divider {
  width: 100%;
  background-color: #fff;
  height: 1px;
  display: inline-block;
}
.footer__columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin: 0;
  width: 100%;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.footer__columns li {
  margin-bottom: 5px;
}
.footer__columns h4 {
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 700;
  color: #ffe3a8;
  text-decoration: underline;
}
.footer__columns a {
  text-decoration: none;
  font-size: 16px;
  color: #fff;
}
.footer__columns .footer__last-column {
  display: inline-block;
}
.footer__language {
  color: #fff;
  display: inline-flex;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
}
.footer__language .title {
  color: #fff;
  font-size: 18px;
}
.footer__language .title__text {
  line-height: 30px;
  height: 30px;
}
.footer__language .title__dropdown-button {
  top: 0px;
}
.footer__language .button--directional {
  width: 20px;
  height: 20px;
  margin-top: 0;
}
.footer__language .responsiveList {
  width: 300px;
}
.footer__information-pages {
  font-size: 12px;
  display: inline-block;
  color: white;
  width: 100%;
}
.footer__information-pages ul {
  display: flex;
}
.footer__information-pages a {
  text-decoration: none;
  color: white;
  white-space: nowrap;
}
.footer__column-wrapper {
  display: inline-flex;
  flex-direction: column;
}
.footer__social-media-icons {
  display: flex;
}
.footer__social-media-icons li {
  margin-right: 14px;
  background-color: #ffe3a8;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  position: relative;
}
.footer__social-media-icons li:last-child {
  margin-right: 0px;
}
.footer__social-media-icons li img {
  color: #00b0bb;
}
.footer__social-media-icons li img {
  width: 14.2857142857px;
  height: 14.2857142857px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.footer__copyright {
  display: inline-block;
  color: white;
}
.footer__stamp {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 60%;
}
.footer__stamp img {
  height: 100%;
  width: auto;
}
.footer .dropdownBox.-open {
  margin-bottom: 20px;
}
.footer__send-message {
  width: 100%;
  height: 50px;
  line-height: 50px;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  position: absolute;
  color: white;
  text-align: center;
  transition: opacity 1s ease;
}
.footer__send-message.-hidden {
  opacity: 0;
  pointer-events: none;
}
.footer__send-message.-visible {
  opacity: 1;
}
.footer__email-form {
  grid-gap: 0px;
  display: grid;
  grid-template-columns: 1fr auto;
  transition: opacity 1s ease;
  grid-template-areas: "Input Button" "VISITED VISITED" "GDPR GDPR";
}
.footer__email-form.-visible {
  opacity: 1;
}
.footer__email-form.-hidden {
  opacity: 0;
  pointer-events: none;
}
.footer__email-form .label__error {
  color: rgba(255, 255, 255, 0.8) !important;
}
.footer__email-form-input {
  grid-area: Input;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.footer__email-form-btn {
  grid-area: Button;
}
.footer__email-gdpr {
  grid-area: GDPR;
}
.footer__email-gdpr .checkbox {
  margin-top: 0;
}
.footer__email-gdpr .richText p {
  margin-top: 0;
}
.footer__email-gdpr .checkbox__error {
  color: rgba(255, 255, 255, 0.8) !important;
}
.footer__email-visited {
  margin-bottom: 0;
  grid-area: VISITED;
}
.footer__email-visited .checkbox__error {
  color: rgba(255, 255, 255, 0.8) !important;
}

@media (min-width: 992px) {
  .footer {
    margin-top: 108px;
    /**
    * Email container
    **/
    /**
    * Divider
    **/
    /**
    * Footer Columns
    **/
  }
  .footer .button--email-form {
    float: right;
  }
  .footer__email-container {
    padding-top: 54px;
    grid-template-columns: 33% 1fr;
    grid-gap: 18px;
    grid-template-areas: "email-text email-form";
  }
  .footer__email-text {
    max-width: 520px;
  }
  .footer__email-form {
    width: 450px;
    float: right;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: "Input Button" "VISITED VISITED" "GDPR GDPR";
  }
  .footer__email-form-note {
    text-align: right;
  }
  .footer__divider {
    margin: 28px 0;
  }
  .footer__columns .footer__last-column {
    float: right;
    text-align: center;
  }
  .footer__columns .footer__column {
    margin: 0 100px 10px 0;
    float: left;
  }
  .footer__social-media-icons {
    margin-top: 5px;
  }
  .footer__logo img {
    margin-top: 20px;
    max-width: 250px;
  }
  .footer__copyright {
    float: left;
  }
  .footer__information-pages {
    margin-top: 54px;
  }
  .footer__information-pages ul {
    float: left;
  }
  .footer__information-pages ul + .footer__copyright {
    float: right;
  }
  .footer__information-pages ul li {
    margin-right: 20px;
  }
}
@media (max-width: 991px) {
  .footer {
    margin-top: 108px;
    /**
    * Divider
    **/
    /**
    * Footer Columns
    **/
  }
  .footer__email-container {
    padding-top: 72px;
    grid-template-columns: 1fr;
    grid-gap: 54px;
    grid-template-areas: "email-text" "email-form";
  }
  .footer__email-text {
    text-align: center;
  }
  .footer__email-form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 90vw;
    /* autoprefixer: ignore next */
    grid-template-columns: 1fr 1fr;
  }
  .footer__email-form-note {
    text-align: center;
  }
  .footer__divider {
    margin: 54px 0;
  }
  .footer__columns {
    text-align: center;
  }
  .footer__columns .footer__column {
    width: 100%;
  }
  .footer__columns .footer__column a {
    width: 100%;
    display: block;
    height: auto;
  }
  .footer__columns .footer__column a p {
    width: 100%;
  }
  .footer__columns .footer__last-column {
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }
  .footer__column li a p {
    white-space: nowrap;
    padding-right: 5px;
    font-size: 14px;
  }
  .footer__last-column {
    margin-bottom: 54px !important;
  }
  .footer__social-media-icons {
    text-align: center;
    left: 50%;
    margin-top: 50px;
  }
  .footer__social-media-icons li {
    margin-right: 15px;
    width: 30px;
    height: 30px;
  }
  .footer__social-media-icons li img {
    width: 14.2857142857px;
    height: 14.2857142857px;
  }
  .footer__column-wrapper {
    display: inline-flex;
    margin: 0 auto;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .footer__logo img {
    margin-top: 60px;
    max-width: 250px;
  }
  .footer__copyright {
    margin: 9px auto;
    text-align: center;
    width: 100%;
  }
  .footer__information-pages {
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
  }
  .footer__information-pages ul {
    flex-direction: row;
    flex-wrap: nowrap;
    /* autoprefixer: ignore next */
    justify-content: space-between;
    /* autoprefixer: ignore next */
    align-items: stretch;
    /* autoprefixer: ignore next */
    align-content: stretch;
    max-width: 500px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    display: grid;
    grid-template-columns: minmax(0px, 33%) minmax(0px, 33%) minmax(0px, 33%);
    grid-template-rows: 1auto;
    grid-template-areas: ". . .";
  }
  .footer__information-pages ul li a {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
  }
  .footer__information-pages ul li:first-child a {
    text-align: left;
  }
  .footer__information-pages ul li:last-child a {
    text-align: right;
  }
  .footer__information-pages ul li {
    height: 35px;
    margin: 0 20px 0 20px;
    box-sizing: border-box;
    position: relative;
  }
  .footer__stamp {
    right: 0;
    bottom: 0;
    height: 200px;
  }
}
@media (min-width: 765px) and (max-width: 991px) {
  .footer__stamp {
    right: 0;
    bottom: 0;
    height: 300px;
  }
}
@media (max-width: 764px) {
  .footer .container {
    padding-bottom: 70px;
  }
  .footer__language {
    width: 100%;
  }
  .footer__language .responsiveList {
    width: 100%;
  }
  .footer__columns {
    grid-template-columns: 1fr;
  }
  .footer__columns h4.footer__heading-inline {
    margin-top: 20px;
  }
  .footer__social-media-icons {
    margin-top: 5px;
  }
}
