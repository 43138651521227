/* mixin for multiline */ /*
Media query mixin
 */
.linkListItem {
  width: 100%;
  position: relative;
  text-align: left;
}
.linkListItem__label {
  width: 100%;
  height: 30px;
  position: relative;
  display: block;
  padding: 0px 9px 0px 49px;
  font-size: 14px;
  font-weight: 700 !important;
  box-sizing: border-box;
  line-height: 30px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #434343;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "brandon-grotesque", sans-serif !important;
  font-weight: 500;
}
.linkListItem__label:hover {
  color: #3399ff;
}
.linkListItem__icon {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 25px;
  width: 25px;
  margin: 2.5px;
}
