/* mixin for multiline */ /*
Media query mixin
 */
.formMessage {
  background-color: #f2f2f4;
  padding: 54px;
  text-align: center;
  display: grid;
  grid-gap: 18px;
}
.formMessage__img {
  width: 75px;
  height: 75px;
  object-fit: contain;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
}
.formMessage__text {
  position: relative;
  font-family: Arial;
  max-width: 300px;
  transform: translate(-50%, 0%);
  left: 50%;
}
