/* mixin for multiline */ /*
Media query mixin
 */
.nav {
  /**
  * Language dropdown
  **/
}
.nav__language {
  color: #fff;
  display: inline-flex;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
}
.nav__language .title {
  color: #fff;
  font-size: 18px;
}
.nav__language .title__text {
  line-height: 30px;
  height: 30px;
}
.nav__language .title__dropdown-button {
  top: 0px;
}
.nav__language .button--directional {
  width: 20px;
  height: 20px;
}
.nav__language .responsiveList {
  width: 300px;
}

@media (max-width: 764px) {
  .nav__language {
    width: 100%;
  }
  .nav__language .responsiveList {
    width: 100%;
  }
}
