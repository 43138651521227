/* mixin for multiline */ /*
Media query mixin
 */
.cardTopLabel {
  height: 25px;
  line-height: 25px;
  display: inline-block;
  vertical-align: top;
  padding: 0px 15px;
  background-color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: white;
}
