/* mixin for multiline */ /*
Media query mixin
 */
.locationMarker__text {
  font-size: 35px;
  font-weight: 700;
  font-family: "brandon-grotesque", sans-serif;
  fill: #434343;
}
.locationMarker .locationMarker__close {
  display: none;
}
.locationMarker.-normal .locationMarker__marker {
  fill: #434343;
}
.locationMarker.-normal:hover .locationMarker__marker {
  fill: #3399ff;
}
.locationMarker.-disabled.cat50311154 .locationMarker__marker {
  fill: #FF9999; /* tours */
}
.locationMarker.-disabled.cat3TK0bvvgufM3Wo27aSflUD .locationMarker__marker {
  fill: #9225C9; /* all-inclusive */
}
.locationMarker.-disabled.cat3NNEcfN9B5kwUakuiplV1B .locationMarker__marker {
  fill: #2E50A2; /* vacation homes */
}
.locationMarker.-disabled.cat6SO8kpnGJe2mSPJE2fF1XE .locationMarker__marker {
  fill: #EE2595; /* villas & apartments */
}
.locationMarker.-disabled.cat2188822371 .locationMarker__marker {
  fill: #F37C00; /* hotels & resorts */
}
.locationMarker.-disabled.cat1752395139 .locationMarker__marker {
  fill: #EE2595; /* art & culture */
}
.locationMarker.-disabled.cat3124011190 .locationMarker__marker {
  fill: #E43BF8; /* nightlife & entertainment */
}
.locationMarker.-disabled.cat74803894 .locationMarker__marker {
  fill: #FBD05D; /* sports & golf */
}
.locationMarker.-disabled.cat2873581994 .locationMarker__marker {
  fill: #F4A71C; /* museums */
}
.locationMarker.-disabled.cat1161287770 .locationMarker__marker {
  fill: #0FCE9A; /* nature & parks */
}
.locationMarker.-disabled.cat187643091 .locationMarker__marker {
  fill: #F37C00; /* land adventures */
}
.locationMarker.-disabled.cat944922909 .locationMarker__marker {
  fill: #9225C9; /* shopping areas */
}
.locationMarker.-disabled.cat924312841 .locationMarker__marker {
  fill: #F84663; /* sights & landmarks */
}
.locationMarker.-disabled.cat3277143415 .locationMarker__marker {
  fill: #41246A; /* spa & wellness */
}
.locationMarker.-disabled.cat215576899 .locationMarker__marker {
  fill: #41246A; /* beaches */
}
.locationMarker.-disabled.cat71597964 .locationMarker__marker {
  fill: #F26450; /* food & drink */
}
.locationMarker.-disabled.cat3829701790 .locationMarker__marker {
  fill: #0A47FA; /* water activities */
}
.locationMarker.-disabled.cat2RwWQYR6aMqOnoCqTHhT0B .locationMarker__marker {
  fill: #0A47FA; /* scuba diving & snorkeling */
}
.locationMarker.-disabled.cat126134492 .locationMarker__marker {
  fill: #636466; /* other */
}
.locationMarker.-disabled.cat7xbzb5ZYW8KtBZlrlNlXq0 .locationMarker__marker {
  fill: #636466; /* utilities */
}
.locationMarker.-disabled .locationMarker__marker {
  fill: #434343;
}
.locationMarker.-active.cat50311154 .locationMarker__marker {
  fill: #FF9999; /* tours */
}
.locationMarker.-active.cat3TK0bvvgufM3Wo27aSflUD .locationMarker__marker {
  fill: #9225C9; /* all-inclusive */
}
.locationMarker.-active.cat3NNEcfN9B5kwUakuiplV1B .locationMarker__marker {
  fill: #2E50A2; /* vacation homes */
}
.locationMarker.-active.cat6SO8kpnGJe2mSPJE2fF1XE .locationMarker__marker {
  fill: #EE2595; /* villas & apartments */
}
.locationMarker.-active.cat2188822371 .locationMarker__marker {
  fill: #F37C00; /* hotels & resorts */
}
.locationMarker.-active.cat1752395139 .locationMarker__marker {
  fill: #EE2595; /* art & culture */
}
.locationMarker.-active.cat3124011190 .locationMarker__marker {
  fill: #E43BF8; /* nightlife & entertainment */
}
.locationMarker.-active.cat74803894 .locationMarker__marker {
  fill: #FBD05D; /* sports & golf */
}
.locationMarker.-active.cat2873581994 .locationMarker__marker {
  fill: #F4A71C; /* museums */
}
.locationMarker.-active.cat1161287770 .locationMarker__marker {
  fill: #0FCE9A; /* nature & parks */
}
.locationMarker.-active.cat187643091 .locationMarker__marker {
  fill: #F37C00; /* land adventures */
}
.locationMarker.-active.cat944922909 .locationMarker__marker {
  fill: #9225C9; /* shopping areas */
}
.locationMarker.-active.cat924312841 .locationMarker__marker {
  fill: #F84663; /* sights & landmarks */
}
.locationMarker.-active.cat3277143415 .locationMarker__marker {
  fill: #41246A; /* spa & wellness */
}
.locationMarker.-active.cat215576899 .locationMarker__marker {
  fill: #41246A; /* beaches */
}
.locationMarker.-active.cat71597964 .locationMarker__marker {
  fill: #F26450; /* food & drink */
}
.locationMarker.-active.cat3829701790 .locationMarker__marker {
  fill: #0A47FA; /* water activities */
}
.locationMarker.-active.cat2RwWQYR6aMqOnoCqTHhT0B .locationMarker__marker {
  fill: #0A47FA; /* scuba diving & snorkeling */
}
.locationMarker.-active.cat126134492 .locationMarker__marker {
  fill: #636466; /* other */
}
.locationMarker.-active.cat7xbzb5ZYW8KtBZlrlNlXq0 .locationMarker__marker {
  fill: #636466; /* utilities */
}
.locationMarker.-active .locationMarker__marker {
  fill: #3399ff;
}
.locationMarker.-close .locationMarker__marker {
  fill: #3399ff;
}
.locationMarker.-close:hover .locationMarker__close {
  display: block;
}
