/* mixin for multiline */ /*
Media query mixin
 */
.container {
  display: block;
}

@media (min-width: 765px) {
  .container {
    margin: 0 10vw;
  }
}
@media (min-width: 765px) and (max-width: 991px) {
  .container {
    margin: 0 10vw;
  }
}
@media (max-width: 764px) {
  .container {
    margin: 0 5vw;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1100px;
    margin: 0px auto;
  }
}
@media (min-width: 1200px) {
  .container.-thin {
    max-width: 1000px;
    margin: 0px auto;
  }
}
