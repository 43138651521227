/* autoprefixer grid: autoplace */ /* mixin for multiline */ /*
Media query mixin
 */
.gdprConsent {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "Icon Text Right";
  grid-template-rows: auto;
  grid-gap: 18px;
  /* autoprefixer: ignore next */
  align-items: center;
}
.gdprConsent__icon {
  height: 40px;
  width: 40px;
  margin: 5px 10px 5px 0px;
  display: inline-block;
  grid-area: Icon;
}
.gdprConsent__icon-img {
  width: 100%;
  height: 100%;
}
.gdprConsent__text {
  grid-area: Text;
}
.gdprConsent__right {
  grid-area: Right;
  text-align: right;
}
.gdprConsent__checkbox {
  display: inline-block;
  margin-right: 18px;
}
.gdprConsent__outer {
  opacity: 1;
}
.gdprConsent__outer.-transparent {
  opacity: 0;
}

@media (max-width: 764px) {
  .gdprConsent {
    grid-template-columns: 1fr;
    grid-template-areas: "Text" "Right";
    grid-template-rows: auto auto;
  }
  .gdprConsent__icon {
    display: none;
  }
}
