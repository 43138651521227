/* mixin for multiline */ /*
Media query mixin
 */
.searchSVG .searchSVG__inside {
  transition: fill 0.2s ease;
}
.searchSVG .searchSVG__border {
  transition: fill 0.2s ease;
}
.searchSVG.-blue .searchSVG__inside {
  fill: rgba(0, 0, 0, 0);
}
.searchSVG.-blue .searchSVG__border {
  fill: #3399ff;
}
.searchSVG.-blue:hover .searchSVG__inside {
  fill: #ee2595;
}
.searchSVG.-blue:hover .searchSVG__border {
  fill: rgba(0, 0, 0, 0);
}
.searchSVG.-blue-active .searchSVG__inside {
  fill: #ee2595;
}
.searchSVG.-blue-active .searchSVG__border {
  fill: rgba(0, 0, 0, 0);
}
.searchSVG.-grey .searchSVG__inside {
  fill: rgba(0, 0, 0, 0.2);
}
.searchSVG.-grey .searchSVG__border {
  fill: white;
}
.searchSVG.-grey:hover .searchSVG__border {
  fill: #f3b038;
}
.searchSVG.-grey-dark .searchSVG__inside {
  fill: rgba(0, 0, 0, 0.2);
}
.searchSVG.-grey-dark .searchSVG__border {
  fill: #434343;
}
.searchSVG.-grey-dark:hover .searchSVG__border {
  fill: #ee2595;
}
.searchSVG.-active .searchSVG__inside {
  fill: #ee2595;
}
.searchSVG.-active .searchSVG__border {
  fill: white;
}
.searchSVG.-disabled .searchSVG__inside {
  fill: rgba(0, 0, 0, 0.2);
}
.searchSVG.-disabled .searchSVG__border {
  fill: white;
}
