/* mixin for multiline */ /*
Media query mixin
 */
a.button--important-alt, a.button--light, a.button--important, a.button--basic, a.button--ed-card {
  position: relative;
  height: 48px;
  line-height: 48px;
  padding: 0px 36px;
  text-align: center;
  font-size: 16px;
  display: inline-block;
  font-family: "brandon-grotesque", sans-serif;
  text-transform: uppercase;
}
a.button--important-alt h6, a.button--light h6, a.button--important h6, a.button--basic h6, a.button--ed-card h6 {
  line-height: inherit;
}

a.button--ed-card {
  color: white;
  background-color: #f4a71c;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
a.button--ed-card:hover {
  background: rgba(244, 167, 28, 0.85);
}
a.button--basic {
  background: #3399ff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  color: white;
  line-height: 48px;
}
a.button--basic:hover {
  background-color: rgba(51, 153, 255, 0.85);
}
a.button--important {
  background: linear-gradient(-90deg, #e77610, #ffde4f);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  color: white !important;
}
a.button--important:hover {
  background: linear-gradient(-90deg, #e77610, #f4a71c);
}
a.button--light {
  background: #f2f2f4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  color: #3399ff;
}
a.button--light:hover {
  background-color: #3399ff;
  color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
a.button--important-alt {
  background-image: linear-gradient(290deg, #9225c9 0%, #390e61 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  color: white;
}
a.button--important-alt:hover {
  background-image: linear-gradient(290deg, #9225c9 -20%, #390e61 150%);
}
