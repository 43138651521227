/* mixin for multiline */ /*
Media query mixin
 */
.toggleButton {
  width: 50px;
  height: 25px;
  border-radius: 25px;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  display: inline-block;
  transition: background-color ease 0.2s;
}
.toggleButton:after {
  content: "";
  width: 17px;
  height: 17px;
  position: absolute;
  margin: 4px;
  background: white;
  border-radius: 100%;
  top: 0px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  transition: left ease 0.2s;
}
.toggleButton:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-repeat: no-repeat;
  background-position: 8.3333333333px 50%;
  background-size: 12.5px;
  background-image: url("../../Assets/images/ui/checkmark-white.svg");
  opacity: 0.4;
  transition: opacity ease 0.2s;
}
.toggleButton.-on {
  background-color: #9ccb3b;
}
.toggleButton.-on:after {
  left: 25px;
}
.toggleButton.-on:before {
  opacity: 1;
}
.toggleButton.-off {
  background-color: #979797;
}
.toggleButton.-off:after {
  left: 0px;
}
