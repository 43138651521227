/* mixin for multiline */ /*
Media query mixin
 */
.submitButton {
  width: 100%;
  color: white;
  text-transform: capitalize !important;
  font-family: "brandon-grotesque", sans-serif !important;
  position: relative;
  margin: 18px 0px;
  border: none;
  cursor: pointer;
}
.submitButton.-active {
  background-color: #3399ff !important;
}
.submitButton.-disabled {
  background-color: #434343 !important;
}

@media (min-width: 765px) {
  .submitButton {
    left: 50%;
    max-width: 300px;
    transform: translate(-50%, 0%);
  }
}
