/* mixin for multiline */ /*
Media query mixin
 */
.button--directional {
  position: relative;
  height: 48px;
  line-height: 48px;
  padding: 0px 36px;
  text-align: center;
  font-size: 16px;
  display: inline-block;
  font-family: "brandon-grotesque", sans-serif;
  text-transform: uppercase;
}
.button--directional h6 {
  line-height: inherit;
}

.button--directional {
  background-color: #3399ff;
  width: 25px;
  height: 25px;
  padding: 0;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 4;
}
.button--directional:hover {
  background-color: #1f85eb;
}
.button--directional:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 5;
  background-image: url("../../../Assets/images/ui/shallow-arrow-white.svg");
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: 50% 50%;
}
.button--directional.-up:before {
  transform: rotate(-90deg);
}
.button--directional.-right:before {
  transform: rotate(0deg);
}
.button--directional.-down:before {
  transform: rotate(90deg);
}
.button--directional.-left:before {
  transform: rotate(180deg);
}
.button--directional.-cross:before {
  background-image: url("../../../Assets/images/ui/close-x.svg");
}
.button--directional.-light {
  background-color: #f2f2f4;
}
.button--directional.-light:hover {
  background-color: #3399ff;
}
.button--directional.-light:hover:before {
  background-image: url("../../../Assets/images/ui/shallow-arrow-white.svg");
}
.button--directional.-light:before {
  background-image: url("../../../Assets/images/ui/shallow-arrow-blue.svg");
}
