/* mixin for multiline */ /*
Media query mixin
 */
.button--important-alt, .button--light, .button--important, .button--basic, .button--ed-card {
  position: relative;
  height: 48px;
  line-height: 48px;
  padding: 0px 36px;
  text-align: center;
  font-size: 16px;
  display: inline-block;
  font-family: "brandon-grotesque", sans-serif;
  text-transform: uppercase;
}
.button--important-alt h6, .button--light h6, .button--important h6, .button--basic h6, .button--ed-card h6 {
  line-height: inherit;
}

.button--ed-card {
  color: white;
  background-color: #f4a71c;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.button--ed-card:hover {
  background: rgba(244, 167, 28, 0.85);
}
.button--basic {
  background: #3399ff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  color: white;
  line-height: 48px;
}
.button--basic:hover {
  background-color: rgba(51, 153, 255, 0.85);
}
.button--important {
  background: linear-gradient(-90deg, #e77610, #ffde4f);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  color: white;
}
.button--important:hover {
  background: linear-gradient(-90deg, #e77610, #f4a71c);
}
.button--light {
  background: #f2f2f4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  color: #3399ff;
}
.button--light:hover {
  background-color: #3399ff;
  color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.button--important-alt {
  background-image: linear-gradient(290deg, #9225c9 0%, #390e61 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  color: white;
}
.button--important-alt:hover {
  background-image: linear-gradient(290deg, #9225c9 -20%, #390e61 150%);
}
