/* mixin for multiline */ /*
Media query mixin
 */
.card__description, .card__subtitle, .card__title, .card__caption, .card {
  text-decoration: none;
}
.card__description:link, .card__subtitle:link, .card__title:link, .card__caption:link, .card:link {
  color: inherit;
  text-decoration: none;
}

@media (pointer: fine) {
  .card:hover.-hover-background {
    background-color: #f2f2f4;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  }
  .card:hover .card__img-container::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: 5px solid #ffde4f;
    box-sizing: border-box;
  }
  .card:hover .card__img-top-content {
    margin: 5px;
    width: calc(100% - 10px);
  }
  .card:hover .card__img-bottom-content {
    margin: 5px;
    width: calc(100% - 10px);
  }
}
.card {
  width: 100%;
  vertical-align: top;
  text-align: left;
  position: relative;
  cursor: pointer;
}
.card.-disabled {
  opacity: 0.5;
}
.card.-view-all.card {
  background-color: #f2f2f4;
}
.card.-view-all.card:hover .card__img-container::after {
  content: unset;
}
.card.-view-all .card__img-container {
  opacity: 0.2;
}
.card.-view-all .card__caption {
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  padding-left: 12px !important;
  text-align: center;
  transform: translateY(-50%);
}
.card.-view-all .card__title {
  position: relative;
}
.card.-view-all .card__title::after {
  content: "";
  position: absolute;
  border: 1px solid #434343;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  margin-left: 10px;
  top: 5px;
  background-image: url(../../Assets/images/ui/shallow-arrow-dark.svg);
  background-repeat: no-repeat;
  background-size: 7px;
  background-position: 50% 50%;
  z-index: 999;
}
.card__above-content {
  width: 100%;
  position: absolute;
  top: -25px;
  height: 25px;
}
.card__card-rating-top {
  margin: 9px 18px;
  color: white;
  font-size: 12px;
  font-family: Arial, Tahoma, "Bitstream Vera Sans", sans-serif;
}
.card__card-rating-bottom {
  display: block;
  margin: 0px 0px 9px 0px;
  color: #767676;
  font-family: Arial, Tahoma, "Bitstream Vera Sans", sans-serif;
  font-size: 12px;
}
.card__card-rating {
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;
}
.card__trip-advisor-svg {
  height: 17px;
  margin-right: 5px;
  margin-bottom: -2px;
  margin-top: 20px;
}
.card.-square .card__img-container {
  padding-top: 100%;
}
.card.-square .card__caption {
  padding-left: 0px;
}
.card__favourites {
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0px;
  right: 0px;
  margin: 9px;
}
.card__img-container {
  width: 100%;
  height: 0px;
  display: block;
  position: relative;
  box-sizing: border-box;
  padding-top: 70%;
}
.card__img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-sizing: border-box;
}
.card__img-bottom-content {
  position: absolute;
  margin: 0px;
  box-sizing: border-box;
  color: white;
  bottom: 0px;
  left: 0px;
  width: 100%;
  vertical-align: bottom;
}
.card__img-bottom-content.-fade {
  min-height: 80px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(57, 58, 60, 0.9));
}
.card__img-top-content {
  position: absolute;
  margin: 0px;
  box-sizing: border-box;
  color: white;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
}
.card__caption {
  display: block;
  padding: 12px;
  box-sizing: border-box;
}
.card.-hover-background .card__caption {
  padding: 12px;
}
.card__title {
  text-transform: capitalize;
  word-wrap: break-word;
  white-space: normal;
}
.card__subtitle {
  text-transform: capitalize;
  word-wrap: break-word;
  white-space: normal;
  color: #3399ff;
  margin: 9px 0px;
}
.card__description {
  display: block;
}
