/* mixin for multiline */ /*
Media query mixin
 */
.cardLabel {
  color: white;
  padding: 0px 6px;
  width: auto;
  margin: 9px;
  vertical-align: top;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  background-color: rgba(0, 0, 0, 0.3);
  text-transform: capitalize;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 900;
  font-size: 14px;
  border: 2px white solid;
}
