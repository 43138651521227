/* mixin for multiline */ /*
Media query mixin
 */
.textInput {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  outline: none;
  background-color: #f2f2f4;
  border: none;
}
.textInput::placeholder {
  color: rgba(1, 1, 1, 0.2);
}

@media (min-width: 765px) {
  .textInput {
    padding: 18px 36px;
  }
}
@media (max-width: 764px) {
  .textInput {
    padding: 18px 18px;
  }
  .textInput.-transparent {
    color: white;
    background-color: rgba(255, 255, 255, 0.35) !important;
  }
  .textInput.-transparent::placeholder {
    opacity: 1;
    color: #f2f2f4 !important;
  }
}
