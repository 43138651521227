/* mixin for multiline */ /*
Media query mixin
 */
.dropdownBox {
  margin-top: 18px;
  color: #434343;
  background-color: #FFFFFF;
  position: absolute;
  overflow: hidden;
  transition: max-height 0.2s linear;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.dropdownBox.-open {
  z-index: 500;
  max-height: 80vh;
}
.dropdownBox.-closed {
  max-height: 0px;
}

@media (max-width: 764px) {
  .dropdownBox {
    overflow-y: auto;
    margin-top: 9px;
  }
}
