/* autoprefixer grid: autoplace */
/* mixin for multiline */ /*
Media query mixin
 */
.emptyCard__image {
  background-color: rgba(255, 255, 255, 0.15);
}
.emptyCard__text1 {
  background-color: rgba(255, 255, 255, 0.15);
}
.emptyCard__text2 {
  background-color: rgba(255, 255, 255, 0.15);
}
.emptyCard > * {
  display: block;
}

@media (min-width: 765px) {
  .emptyCard {
    display: inline-grid;
    width: 230px;
    grid-template-columns: 0;
    grid-template-rows: 11fr 1fr 1fr;
    /* autoprefixer: ignore next */
    grid-gap: 9px;
    min-height: 275px;
  }
  .emptyCard__container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .emptyCard__image {
    width: 100%;
    height: 95%;
  }
  .emptyCard__text1 {
    width: 80%;
    height: 100%;
  }
  .emptyCard__text2 {
    width: 30%;
    height: 100%;
  }
  .emptyCard__empty-img {
    position: absolute;
    width: 30%;
    bottom: 50%;
    object-position: 50% 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  .emptyCard__text {
    width: 100%;
    height: 50%;
    color: white !important;
    white-space: pre-wrap;
    line-height: 1.2em !important;
    text-align: center;
    padding: 25px 0px 0px 0px;
    position: absolute;
    top: 50%;
  }
  .emptyCard.-empty-img {
    width: 250px;
    margin-right: 54px !important;
    display: inline-block;
    vertical-align: top;
    position: relative;
  }
}
@media (max-width: 764px) {
  .emptyCard {
    display: block;
    width: 100%;
    margin-bottom: 18px;
    position: relative;
  }
  .emptyCard__container {
    position: relative;
  }
  .emptyCard__container.-img {
    width: 120px;
    height: 120px;
  }
  .emptyCard__container.-txt1 {
    position: absolute;
    width: calc(90% - 120px - 18px);
    left: 138px;
    bottom: calc(50% + 4.5px);
    height: 18px;
  }
  .emptyCard__container.-txt2 {
    position: absolute;
    width: calc(60% - 120px - 18px);
    left: 138px;
    top: calc(50% + 4.5px);
    height: 18px;
  }
  .emptyCard__image {
    width: 100%;
    height: 100%;
  }
  .emptyCard__text1 {
    width: 100%;
    height: 100%;
  }
  .emptyCard__text2 {
    width: 100%;
    height: 100%;
  }
  .emptyCard__empty-img {
    width: 100px;
    height: 100px;
    object-position: 50% 100%;
    display: inline-block;
    left: 0px;
    margin: 9px;
  }
  .emptyCard__text {
    width: calc(100% - 100px - 18px);
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    white-space: pre-wrap;
  }
  .emptyCard.-empty-img {
    width: 100%;
    height: 120px;
    display: block;
    position: relative;
  }
}
