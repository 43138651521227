/* mixin for multiline */ /*
Media query mixin
 */
.iconButton {
  object-fit: contain;
  padding: 12px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}
.iconButton-container {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.iconButton-text {
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  color: #39F;
  margin-left: -10px;
}
