/* mixin for multiline */ /*
Media query mixin
 */
.divider {
  width: 100%;
  height: 2px;
  background-color: #f2f2f4;
  outline: none;
  border: none;
}
.divider.-dark {
  background-color: rgba(0, 0, 0, 0.07);
}
