/* mixin for multiline */ /*
Media query mixin
 */
.notFound__container {
  margin-bottom: -108px;
}
.notFound__btn {
  margin: 36px 36px;
}

@media (min-width: 765px) {
  .notFound__img {
    width: 400px;
    height: 600px;
    position: relative;
    left: 60%;
    transform: translateX(-50%);
  }
  .notFound__content {
    margin: 54px 0px;
  }
}
@media (max-width: 764px) {
  .notFound__content {
    min-height: 90vh;
  }
}
