/* mixin for multiline */ /*
Media query mixin
 */
.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: lauto;
  grid-gap: 9px;
}
.form input[type=text],
.form textarea {
  border-radius: 0px;
  outline: none;
  background-color: #f2f2f4;
  border: none;
}
.form input[type=text]::placeholder,
.form textarea::placeholder {
  color: rgba(1, 1, 1, 0.2);
}

@media (min-width: 765px) {
  .form input[type=text],
  .form textarea {
    padding: 18px 36px;
  }
}
@media (max-width: 764px) {
  .form input[type=text],
  .form textarea {
    padding: 18px 18px;
  }
}
