/* mixin for multiline */ /*
Media query mixin
 */
.bannerDialogue {
  width: 100%;
  position: fixed;
  background-color: #3399ff;
  background-attachment: fixed !important;
  bottom: 0px;
  left: 0px;
  z-index: 20000;
  transition: transform 1s ease;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  color: white;
  transform: translateY(200%);
}
.bannerDialogue.-open {
  transform: translateY(0%);
}
.bannerDialogue.-closed {
  transform: translateY(200%);
}
.bannerDialogue__container {
  height: 100%;
  position: relative;
  padding: 24px 0px;
}
