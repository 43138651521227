/* autoprefixer grid: autoplace */ /* mixin for multiline */ /*
Media query mixin
 */
.nav {
  position: sticky;
  box-sizing: border-box;
  z-index: 10000;
  width: 100%;
  background-attachment: fixed !important;
  top: 0px;
  left: 0px;
}
.nav.-type-2.-closed.-scroll-top::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: -2;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}
.nav.-type-3.-closed.-scroll-top::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: -2;
  background-image: linear-gradient(rgb(28, 102, 143), rgba(28, 102, 143, 0.2));
}
.nav__sub-btn {
  color: white !important;
}
.nav__top {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  background: #f23d87;
  z-index: -1;
}
.nav__searchBar {
  position: absolute;
  height: 40px;
  width: 0px;
  overflow: hidden;
  top: 50%;
  transform: translateY(-50%);
  right: calc(54px + 20px + 9px);
  transition: width 0.2s linear;
}
.nav__searchBar-inside {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
.nav__map {
  grid-area: Map;
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.nav__search {
  grid-area: Search;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.nav__searchInput {
  height: 100%;
}
.nav__searchInput .searchInput__input {
  height: 100%;
  background: unset;
  background-color: transparent;
  padding: 0 0 0 18px;
}
.nav__searchInput .searchInput__close {
  right: unset;
  left: -40px;
  background-size: 16px;
  background-image: url("../../Assets/images/ui/close-x.svg");
}
.nav__favourites {
  grid-area: Heart;
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.nav__logo {
  background-image: url("../../Assets/images/logo-dark.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
  grid-area: Logo;
}
.nav__left, .nav__right {
  grid-area: MenuItem;
}
.nav__item-gradient, .nav__item-img {
  display: none;
}
.nav__item {
  opacity: 1;
  transition: opacity 0.2s linear;
  letter-spacing: 1px;
}

@media (min-width: 1201px) {
  .nav {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 125px 1fr 35px 25px 20px 25px;
    grid-template-rows: 80px;
    grid-template-areas: "Logo MenuItem Language Map Search Heart";
    grid-gap: 9px;
    padding: 0px 54px;
  }
  .nav {
    height: 80px;
    padding: 0px 54px;
  }
  .nav.-type-2 {
    position: fixed;
  }
  .nav.-type-3 {
    position: fixed;
  }
  .nav.-scroll-top .nav__top {
    height: 10px;
  }
  .nav.-scroll-top .nav__logo {
    background-image: url("../../Assets/images/logo-dark.svg");
  }
  .nav.-scroll-top .nav__item > a {
    color: #434343;
  }
  .nav.-scroll-top .footer__language h3.title {
    color: #434343;
  }
  .nav.-scroll-top .nav__item:hover > a {
    color: #ee2595;
  }
  .nav.-scroll-top .nav__item > a.selected {
    color: #ffde4f;
  }
  .nav.-scroll-top .nav__searchBar-inside {
    border: 2px solid #434343;
  }
  .nav.-scroll-top .nav__searchBar-inside .nav__searchInput .searchInput__input {
    color: #434343;
  }
  .nav.-scroll-top .nav__searchBar-inside .nav__searchInput .searchInput__input::placeholder {
    color: #434343;
  }
  .nav.-scroll-top .nav__searchBar-inside .searchInput__close {
    background-image: url("../../Assets/images/ui/close-dark.svg");
  }
  .nav.-scroll-top.-type-1 .nav__item > a.selected {
    color: #f3b038;
    text-decoration: underline;
  }
  .nav.-scroll-top.-type-2 .nav__logo {
    background-image: url("../../Assets/images/logo-light.svg");
  }
  .nav.-scroll-top.-type-2 .nav__item > a {
    color: white;
  }
  .nav.-scroll-top.-type-2 .nav__item > a:hover {
    color: #f3b038;
  }
  .nav.-scroll-top.-type-2 .nav__item > a.selected {
    color: #f3b038;
    text-decoration: underline;
  }
  .nav.-scroll-top.-type-2 .footer__language h3.title {
    color: white;
  }
  .nav.-scroll-top.-type-2 .footer__language h3.title:hover {
    color: #f3b038;
  }
  .nav.-scroll-top.-type-2 .nav__searchBar-inside {
    border: 2px solid #f2f2f4;
  }
  .nav.-scroll-top.-type-2 .nav__searchBar-inside .nav__searchInput .searchInput__input {
    color: #f2f2f4;
  }
  .nav.-scroll-top.-type-2 .nav__searchBar-inside .nav__searchInput .searchInput__input::placeholder {
    color: #f2f2f4;
  }
  .nav.-scroll-top.-type-2 .nav__searchBar-inside .searchInput__close {
    background-image: url("../../Assets/images/ui/close-x.svg");
  }
  .nav.-scroll-top.-type-3 .nav__logo {
    background-image: url("../../Assets/images/logo-light.svg");
  }
  .nav.-scroll-top.-type-3 .nav__item > a {
    color: white;
  }
  .nav.-scroll-top.-type-3 .nav__item > a:hover {
    color: #f3b038;
  }
  .nav.-scroll-top.-type-3 .nav__item > a.selected {
    color: #f3b038;
    text-decoration: underline;
  }
  .nav.-scroll-top.-type-3 .footer__language h3.title {
    color: white;
  }
  .nav.-scroll-top.-type-3 .footer__language h3.title:hover {
    color: #f3b038;
  }
  .nav.-scroll-top.-type-3 .nav__searchBar-inside {
    border: 2px solid #f2f2f4;
  }
  .nav.-scroll-top.-type-3 .nav__searchBar-inside .nav__searchInput .searchInput__input {
    color: #f2f2f4;
  }
  .nav.-scroll-top.-type-3 .nav__searchBar-inside .nav__searchInput .searchInput__input::placeholder {
    color: #f2f2f4;
  }
  .nav.-scroll-top.-type-3 .nav__searchBar-inside .searchInput__close {
    background-image: url("../../Assets/images/ui/close-x.svg");
  }
  .nav.-scroll-down .nav__top {
    height: 80px;
  }
  .nav.-scroll-down .nav__logo {
    background-image: url("../../Assets/images/logo-light.svg");
  }
  .nav.-scroll-down .nav__left .nav__item > a {
    color: #FFF;
  }
  .nav.-scroll-down .nav__right .nav__item:hover > a, .nav.-scroll-down .nav__right .nav__item > a {
    color: #f23d87;
    background: #f3b038;
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
    padding: 7px 9px;
  }
  .nav.-scroll-down .footer__language > .title {
    /* color: $color-grey-dark;*/
  }
  .nav.-scroll-down .footer__language > .title h3.title {
    color: #FFF;
  }
  .nav.-scroll-down .footer__language > .title:hover {
    cursor: default;
  }
  .nav.-scroll-down .footer__language > .title:hover h3.title {
    color: #f3b038;
  }
  .nav.-scroll-down .nav__item:hover > a, .nav.-scroll-down .nav__item > a.selected {
    color: #f3b038;
    text-decoration: underline;
  }
  .nav.-scroll-down .nav__searchBar-inside {
    border: 2px solid #f2f2f4;
  }
  .nav.-scroll-down .nav__searchBar-inside .nav__searchInput .searchInput__input {
    color: #f2f2f4;
  }
  .nav.-scroll-down .nav__searchBar-inside .nav__searchInput .searchInput__input::placeholder {
    color: #f2f2f4;
  }
  .nav__covid {
    position: absolute;
    top: 80px;
    height: 50px;
    width: 100%;
    background-color: #39f;
    background: #f23d87;
    text-align: center;
    z-index: 1;
  }
  .nav__covid a {
    color: #FFF !important;
    line-height: 50px;
    font-size: 20px;
  }
  .nav__top {
    transition: height 0.2s ease;
  }
  .nav__logo {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    display: inline-block;
  }
  .nav__menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }
  .nav__menu-wrapper {
    /*width: 100%;
    height: 100%;
    position: absolute;
    top: 0px; */
    grid-area: MenuItem;
  }
  .nav__item {
    height: 100%;
    display: inline-block;
    line-height: 80px;
    font-family: "brandon-grotesque", sans-serif;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 700;
  }
  .nav__item.-mobile-only {
    display: none;
  }
  .nav__item > a {
    padding: 9px;
  }
  .nav__item .nav__sub-menu {
    display: none;
  }
  .nav__item:hover .nav__sub-menu {
    display: block;
  }
  .nav__left .nav__item {
    margin-left: 9px;
    text-transform: uppercase;
    background-image: none !important;
  }
  .nav__right {
    text-align: right;
  }
  .nav__right .nav__item {
    margin-right: 9px;
    text-transform: capitalize;
    text-align: left;
    font-weight: 500;
  }
  .nav__right .nav__item::before {
    display: none;
  }
  .nav__right .nav__item.-first {
    position: static;
  }
  .nav__right .nav__item.-first .nav__sub-menu {
    right: 0px;
    left: auto;
    transform: none;
  }
  .nav .footer__language {
    grid-area: Language;
    transition: opacity 0.2s linear;
  }
  .nav .footer__language.footer__language-mobile {
    display: none;
  }
  .nav .footer__language > .title .title__dropdown-container {
    width: unset;
  }
  .nav .footer__language > .title #nav__language-button {
    display: none;
  }
  .nav .footer__language > .title > .title {
    /* cursor: pointer; */
  }
  .nav .footer__language > .title > .title .title__text {
    position: relative;
    font-weight: 700;
    padding-right: 3px;
  }
  .nav .footer__language > .title > .title .title__text:after {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: 14px;
    background: url("../../Assets/Arrows/SVG/down-arrow-white.svg") center center no-repeat;
    background-size: contain;
  }
  .nav .footer__language > .title > .title:hover {
    /* color: $color-pink; */
  }
  .nav .footer__language > .title .title__dropdown-box {
    display: none;
  }
  .nav .footer__language > .title:hover .title__dropdown-box {
    display: block;
    top: 0;
    z-index: 500;
    max-height: 80vh;
    transform: translate(-65%, 62px);
  }
  .nav .footer__language .title {
    display: flex;
    align-items: center;
  }
  .nav .footer__language .title h3.title {
    text-transform: initial;
    font-size: 12px;
    font-weight: 500;
  }
  .nav__sub-menu {
    background-color: #FFFFFF;
    position: absolute;
    left: 0;
    top: 80px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.3);
    padding: 36px 18px;
    column-gap: 36px;
  }
  .nav__sub-menu-container {
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-gap: 60px;
    max-width: 1100px;
    margin: 0px auto;
  }
  .nav__sub-menu-img {
    max-width: 100%;
  }
  .nav__sub-menu ul.-column-1 {
    columns: 1;
  }
  .nav__sub-menu ul.-column-2 {
    columns: 2;
  }
  .nav__sub-menu ul.-column-3 {
    columns: 3;
  }
  .nav__sub-menu ul.-no-button {
    padding: 9px 36px 18px 18px;
  }
  .nav__sub-item {
    margin: 0px;
    height: 36px;
    width: auto;
    max-width: 225px;
    padding: 0px;
    min-width: 150px;
  }
  .nav__sub-item > a {
    display: block;
    color: #434343;
    min-width: 150px;
    box-sizing: border-box;
    height: 36px;
    font-size: 12px;
    line-height: 36px;
    max-width: 225px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .nav__sub-item > a:hover, .nav__sub-item > a.selected {
    color: #00b0bb;
  }
  .nav__sub-btn {
    position: absolute !important;
    right: 18px;
    width: 110px;
    text-align: center;
    padding: 0px;
    box-sizing: border-box;
    bottom: 18px;
  }
  .nav__sub-btn > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    padding: 0px 10px;
  }
  .nav__menu-btn {
    display: none;
  }
}
@media (max-width: 1200px) {
  .nav {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 25px 1fr 25px 25px;
    grid-template-rows: 50px;
    /* autoprefixer: ignore next */
    grid-template-areas: "MenuToggle Logo Map Heart ";
    grid-gap: 9px;
    padding: 0px 18px;
  }
  .nav {
    position: sticky;
    height: 50px;
  }
  .nav__menu {
    background: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    max-height: calc(100vh - 50px);
    padding: 9px 0 9px 24px;
    box-sizing: border-box;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .nav__menu-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 0;
    transition: max-width 0.2s linear;
    overflow: hidden;
  }
  .nav.-type-1 {
    background-color: white;
    position: sticky;
  }
  .nav.-type-1.-open .nav__menu-btn {
    color: white;
  }
  .nav.-type-1.-open .nav__menu-btn::before {
    background-image: url("../../Assets/images/ui/shallow-arrow-white.svg");
  }
  .nav.-type-1 .nav__searchBar .searchInput__close {
    background-image: url(../../Assets/images/ui/close-dark.svg);
  }
  .nav.-type-1 .nav__searchBar-inside {
    border-bottom: 2px solid #979797;
  }
  .nav.-type-1 .nav__searchBar .nav__searchInput .searchInput__input {
    color: #434343;
    padding: 0;
  }
  .nav.-type-1 .nav__searchBar .nav__searchInput .searchInput__input::placeholder {
    color: #979797;
  }
  .nav.-type-1.-scroll-down .nav__searchBar .searchInput__close {
    background-image: url(../../Assets/images/ui/close-x.svg);
  }
  .nav.-type-1.-closed.-scroll-top .nav__menu-hamburger .hamburger-box .hamburger-inner, .nav.-type-1.-closed.-scroll-top .nav__menu-hamburger .hamburger-box .hamburger-inner:before, .nav.-type-1.-closed.-scroll-top .nav__menu-hamburger .hamburger-box .hamburger-inner:after {
    background-color: #434343;
  }
  .nav.-type-1.-closed.-scroll-top .nav__menu-btn {
    color: #434343;
  }
  .nav.-type-1.-closed.-scroll-top .nav__menu-btn::before {
    background-image: url("../../Assets/images/ui/shallow-arrow-dark.svg") !important;
  }
  .nav.-type-1.-closed.-scroll-down .nav__menu-btn {
    color: white;
  }
  .nav.-type-1.-closed.-scroll-down .nav__menu-btn::before {
    background-image: url("../../Assets/images/ui/shallow-arrow-white.svg") !important;
  }
  .nav__covid {
    position: absolute;
    top: 50px;
    height: 50px;
    width: 100%;
    background-color: #39f;
    background: #f23d87;
    text-align: center;
    z-index: 1;
  }
  .nav__covid a {
    color: #FFF !important;
    line-height: 50px;
    font-size: 20px;
  }
  .nav.-type-2 {
    position: fixed;
  }
  .nav.-type-2.-open .nav__menu-btn {
    color: white;
  }
  .nav.-type-2.-open .nav__menu-btn::before {
    background-image: url("../../Assets/images/ui/shallow-arrow-white.svg");
  }
  .nav.-type-2.-open div.nav__covid {
    display: none;
  }
  .nav.-type-2 .nav__searchBar-inside {
    border-bottom: 2px solid #f2f2f4;
  }
  .nav.-type-2 .nav__searchBar .nav__searchInput .searchInput__input {
    color: #f2f2f4;
    padding: 0;
  }
  .nav.-type-2 .nav__searchBar .nav__searchInput .searchInput__input::placeholder {
    color: #f2f2f4;
  }
  .nav.-type-2.-closed.-scroll-top .nav__menu-btn {
    color: white;
  }
  .nav.-type-2.-closed.-scroll-top .nav__menu-btn::before {
    background-image: url("../../Assets/images/ui/shallow-arrow-white.svg") !important;
  }
  .nav.-type-2.-closed.-scroll-top .nav__logo {
    background-image: url("../../Assets/images/logo-light.svg");
  }
  .nav.-type-2.-closed.-scroll-down .nav__menu-btn {
    color: white;
  }
  .nav.-type-2.-closed.-scroll-down .nav__menu-btn::before {
    background-image: url("../../Assets/images/ui/shallow-arrow-white.svg") !important;
  }
  .nav.-type-3 {
    position: fixed;
  }
  .nav.-type-3.-open .nav__menu-btn {
    color: white;
  }
  .nav.-type-3.-open .nav__menu-btn::before {
    background-image: url("../../Assets/images/ui/shallow-arrow-white.svg");
  }
  .nav.-type-3.-open div.nav__covid {
    display: none;
  }
  .nav.-type-3 .nav__searchBar-inside {
    border-bottom: 2px solid #f2f2f4;
  }
  .nav.-type-3 .nav__searchBar .nav__searchInput .searchInput__input {
    color: #f2f2f4;
    padding: 0;
  }
  .nav.-type-3 .nav__searchBar .nav__searchInput .searchInput__input::placeholder {
    color: #f2f2f4;
  }
  .nav.-type-3.-closed.-scroll-top .nav__menu-btn {
    color: white;
  }
  .nav.-type-3.-closed.-scroll-top .nav__menu-btn::before {
    background-image: url("../../Assets/images/ui/shallow-arrow-white.svg") !important;
  }
  .nav.-type-3.-closed.-scroll-top .nav__logo {
    background-image: url("../../Assets/images/logo-light.svg");
  }
  .nav.-type-3.-closed.-scroll-down .nav__menu-btn {
    color: white;
  }
  .nav.-type-3.-closed.-scroll-down .nav__menu-btn::before {
    background-image: url("../../Assets/images/ui/shallow-arrow-white.svg") !important;
  }
  .nav.-scroll-top .nav__top {
    height: 5px;
  }
  .nav.-scroll-top .nav__logo {
    background-image: url("../../Assets/images/logo-dark.svg");
  }
  .nav.-scroll-top .nav__item > a {
    color: #434343;
  }
  .nav.-scroll-top .nav__item:hover > a {
    color: #ee2595;
  }
  .nav.-scroll-down .nav__top {
    height: 50px;
  }
  .nav.-scroll-down .nav__logo {
    background-image: url("../../Assets/images/logo-light.svg");
  }
  .nav.-scroll-down .nav__left .nav__item > a {
    color: #ffde4f;
  }
  .nav.-scroll-down .nav__right .nav__item > a {
    color: #434343;
  }
  .nav.-scroll-down .nav__item:hover > a {
    color: #9225c9;
  }
  .nav.-scroll-down .nav__searchBar-inside {
    border-bottom: 2px solid #f2f2f4;
  }
  .nav.-scroll-down .nav__searchBar .nav__searchInput .searchInput__input {
    color: #f2f2f4;
    padding: 0;
  }
  .nav.-scroll-down .nav__searchBar .nav__searchInput .searchInput__input::placeholder {
    color: #f2f2f4;
  }
  .nav.-closed .nav__menu-wrapper {
    max-width: 0;
  }
  .nav.-closed .nav__menu-btn:before {
    transform: rotate(90deg);
  }
  .nav.-closed .footer__language {
    display: none;
  }
  .nav.-closed .footer__language.footer__language-mobile {
    display: block;
  }
  .nav.-closed .nav__search {
    display: none;
  }
  .nav.-open .nav__menu-btn:before {
    transform: rotate(-90deg);
  }
  .nav.-open .nav__top {
    height: 50px;
    transition: none;
  }
  .nav.-open .nav__logo {
    background-image: url("../../Assets/images/logo-light.svg");
  }
  .nav.-open .nav__menu-wrapper {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 50px);
    /* background-color: rgba(0,0,0,0.6); */
  }
  .nav.-open .nav__item-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0;
    object-fit: cover;
    display: block;
  }
  .nav.-open .nav__item-gradient {
    position: absolute;
    display: block;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 30%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  }
  .nav.-open .nav__sub-menu {
    display: none;
  }
  .nav.-open .nav__sub-menu-img {
    display: none;
  }
  .nav.-open .nav__sub-menu.--active {
    display: block;
    padding: 0 0 18px 0;
  }
  .nav.-open .has-right-arrow-mobile:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 40px;
    top: 0px;
    right: 0px;
    z-index: 2;
    background-image: url(../../Assets/images/ui/shallow-arrow-dark.svg);
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: 50% 50%;
  }
  .nav.-open .has-right-arrow:before, .nav.-open .has-down-arrow:before {
    background-image: url(../../Assets/images/ui/shallow-arrow-dark.svg);
  }
  .nav.-open .nav__searchBar.-mobile {
    position: relative;
    padding-right: 24px;
    width: 100%;
    box-sizing: border-box;
    top: unset;
    right: unset;
    transform: unset;
    overflow: unset;
  }
  .nav.-open .nav__searchBar.-mobile .nav__searchBar-inside {
    border-bottom: 0;
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .nav.-open .nav__searchBar.-mobile .nav__searchInput .searchInput__input {
    color: #141314;
    font-family: "brandon-grotesque", sans-serif;
    padding-left: 36px;
    background-image: url("../../Assets/images/ui/search-dark.svg");
    background-size: 15px;
    background-position: 7px 60%;
    background-repeat: no-repeat;
  }
  .nav.-open .nav__searchBar.-mobile .nav__searchInput .searchInput__input::placeholder {
    color: #979797;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
  }
  .nav.-open .nav__left {
    width: 100%;
    padding-right: 24px;
    box-sizing: border-box;
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .nav.-open .nav__left .nav__item {
    position: relative;
  }
  .nav.-open .nav__left .nav__item .nav__sub-item-special {
    display: none;
  }
  .nav.-open .nav__left .nav__item a {
    display: block;
    height: 40px;
    line-height: 40px;
    color: #22356e;
    text-transform: uppercase;
    font-family: "brandon-grotesque", sans-serif;
    font-weight: 700;
    font-size: 14px;
    width: 100%;
    border-bottom: 0px #979797 solid;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .nav.-open .nav__left .nav__item a.selected {
    color: #434343;
  }
  .nav.-open .nav__left .nav__item.show-submenu .has-right-arrow-mobile:before {
    transform: rotate(90deg);
  }
  .nav.-open .nav__left .nav__item.show-submenu .nav__sub-item a {
    text-transform: uppercase;
    height: unset;
    line-height: 28px;
    font-size: 12px;
    border: 0;
  }
  .nav.-open .nav__left .nav__item a.nav__sub-btn {
    display: none;
  }
  .nav.-open .nav__right {
    width: calc(100% + 24px);
    padding-bottom: 9px;
    margin-left: -24px;
    padding-bottom: 9px;
  }
  .nav.-open .nav__right .nav__item {
    position: relative;
    padding-left: 22px;
  }
  .nav.-open .nav__right .nav__item a {
    color: #FFFFFF;
    background: #f23d87;
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
    padding: 11px 11px;
    white-space: nowrap;
  }
  .nav.-open .nav__right .nav__item a.selected {
    color: #f3b038;
  }
  .nav.-open .nav__search {
    display: none;
  }
  .nav.-open .footer__language {
    display: none;
    padding: 9px 0;
    text-align: left;
    width: 100%;
  }
  .nav.-open .footer__language.footer__language-mobile {
    display: block;
  }
  .nav.-open .footer__language .title {
    color: #3399ff;
    font-size: 14px;
    font-weight: 700;
  }
  .nav.-open .footer__language .title__dropdown-container {
    position: unset;
  }
  .nav.-open .footer__language .title__dropdown-box {
    transform: translate(-2px, -130%);
  }
  .nav.-open .footer__language .button--directional {
    width: 16px;
    height: 16px;
    top: 2px;
    background-color: transparent;
    border: #3399ff 1px solid;
  }
  .nav.-open .footer__language .button--directional:before {
    background-image: url(../../Assets/images/ui/shallow-arrow-blue.svg);
    background-size: 7px;
  }
  .nav.-open .nav__menu-hamburger .hamburger-box {
    top: 2px;
  }
  .nav.-open .nav__menu-hamburger .hamburger-box .hamburger-inner {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
  .nav.-open .nav__menu-hamburger .hamburger-box .hamburger-inner:before {
    transform: rotate(-45deg) translate3d(0, -6px, 0);
    opacity: 0;
  }
  .nav.-open .nav__menu-hamburger .hamburger-box .hamburger-inner:after {
    transform: translate3d(0, -16px, 0) rotate(-90deg);
  }
  .nav__menu-btn {
    grid-area: MenuToggle;
    height: 50px;
    line-height: 50px;
    text-align: left;
    font-family: "brandon-grotesque", sans-serif;
    position: relative;
    padding-left: 7px;
    font-size: 14px;
    font-weight: 700;
    box-shadow: none;
    padding-right: 20px;
  }
  .nav__menu-btn:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 50px;
    top: 0px;
    right: 0px;
    z-index: -1;
    background-image: url("../../Assets/images/ui/shallow-arrow-white.svg");
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: 50% 50%;
  }
  .nav__menu-hamburger {
    grid-area: MenuToggle;
    padding: 0;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .nav__menu-hamburger .hamburger-box {
    width: 25px;
    height: 25px;
    display: inline-block;
    position: relative;
  }
  .nav__menu-hamburger .hamburger-box .hamburger-inner {
    top: 6px;
    left: 0px;
  }
  .nav__menu-hamburger .hamburger-box .hamburger-inner, .nav__menu-hamburger .hamburger-box .hamburger-inner:before, .nav__menu-hamburger .hamburger-box .hamburger-inner:after {
    width: 24px;
    height: 3px;
    background-color: #FFF;
    border-radius: 3px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .nav__menu-hamburger .hamburger-box .hamburger-inner:before, .nav__menu-hamburger .hamburger-box .hamburger-inner:after {
    content: "";
    display: block;
  }
  .nav__menu-hamburger .hamburger-box .hamburger-inner:before {
    top: 8px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s;
  }
  .nav__menu-hamburger .hamburger-box .hamburger-inner:after {
    top: 16px;
  }
  .nav__top {
    transition: height 0.2s ease;
  }
  .nav__logo {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    width: 125px;
    display: inline-block;
  }
}
@media (max-width: 1200px) and (min-width: 765px) {
  .nav.-open .nav__left {
    /* autoprefixer: ignore next */
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 1300px) {
  .nav__menu, .nav {
    /* autoprefixer: ignore next */
    grid-template-columns: 150px 1fr 35px 25px 20px 25px;
  }
  .nav__left .nav__item {
    margin-left: 18px;
  }
  .nav__right .nav__item {
    margin-right: 18px;
  }
}
@media (max-width: 764px) {
  .nav.-open .footer__language .title__dropdown-box {
    transform: translate(-2px, -110%);
  }
}
