/* mixin for multiline */ /*
Media query mixin
 */
.tickListItem {
  width: 100%;
  position: relative;
  text-align: left;
}
.tickListItem__label {
  width: 100%;
  height: 50px;
  position: relative;
  display: block;
  padding: 0px 30px 0px 9px;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
  line-height: 50px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tickListItem__label.-checked {
  color: #3399ff;
  font-weight: 700;
}
.tickListItem__label:hover {
  color: #3399ff;
}
.tickListItem__checkbox {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  position: absolute;
  width: 100%;
  height: 50px;
  left: 0px;
  top: 0px;
  background-repeat: no-repeat;
  background-position: calc(100% - 9px) 50%;
  background-size: 20px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
}
.tickListItem__checkbox:checked {
  background-image: url("../../../Assets/images/ui/checkmark-blue.svg");
  appearance: none;
}
