/* mixin for multiline */ /*
Media query mixin
 */
.mapSVG .mapSVG__inside {
  transition: fill 0.2s ease;
}
.mapSVG .mapSVG__border {
  transition: fill 0.2s ease;
}
.mapSVG.-blue .mapSVG__inside {
  fill: rgba(0, 0, 0, 0);
}
.mapSVG.-blue .mapSVG__border {
  fill: #3399ff;
}
.mapSVG.-blue:hover .mapSVG__inside {
  fill: #ee2595;
}
.mapSVG.-blue:hover .mapSVG__border {
  fill: rgba(0, 0, 0, 0);
}
.mapSVG.-blue-active .mapSVG__inside {
  fill: #ee2595;
}
.mapSVG.-blue-active .mapSVG__border {
  fill: rgba(0, 0, 0, 0);
}
.mapSVG.-grey .mapSVG__inside {
  fill: rgba(0, 0, 0, 0.2);
}
.mapSVG.-grey .mapSVG__border {
  fill: white;
}
.mapSVG.-grey:hover .mapSVG__border {
  fill: #f3b038;
}
.mapSVG.-grey-dark .mapSVG__inside {
  fill: rgba(0, 0, 0, 0.2);
}
.mapSVG.-grey-dark .mapSVG__border {
  fill: #434343;
}
.mapSVG.-grey-dark:hover .mapSVG__border {
  fill: #ee2595;
}
.mapSVG.-active .mapSVG__inside {
  fill: #ee2595;
}
.mapSVG.-active .mapSVG__border {
  fill: white;
}
.mapSVG.-disabled .mapSVG__inside {
  fill: rgba(0, 0, 0, 0.2);
}
.mapSVG.-disabled .mapSVG__border {
  fill: white;
}
